.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0B1A1C;
  max-width: 320px;
  padding: 32px;
  z-index: 1000;
  border-radius: 12px;
}

.modal .modal-title {
  font-size: 24px;
  font-weight: 700;
  /* margin-bottom: 16px; */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.7);
  z-index: 1000;
}

.action {
  display: flex;
}

.action .action-text {
  font-size: 18px;
  margin-left: 12px;
}

.action .action-done {
  color: #3c9360;
}

.action .action-error {
  color: red;
}

.modal .action-button {
  display: block;
  width: 100%;
  padding: 8px 0;
  /* margin-top: 16px; */
  border-radius: 8px;
  background-color: #3c9360;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.modal .action-button[disabled] {
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

/* .close-modal { */
/*   position: absolute; */
/*   top: 0; */
/*   right: 10px; */
/*   cursor: pointer; */
/*   font-size: 30px; */
/* } */
