.staking-notification-container {
  pointer-events: none;
  position: fixed;
  right: 16px;
  bottom: 0;
  z-index: 100;
}

.staking-notification {
  max-width: 350px;
  padding: 12px 16px 16px 16px;
  color: #fff;
  border-radius: 12px;
  animation: inAndOut 12s forwards;
  pointer-events: auto;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
}

.staking-notification-success {
  /* background: linear-gradient(135deg, #135E49 0%, #092F24 100%); */
  background: linear-gradient(135deg, rgba(19, 94, 73, 0.60) 0%, rgba(9, 47, 36, 0.60) 100%);


}

.staking-notification-fail {
  /* background: linear-gradient(135deg, #480B0B 0%, #780000 100%); */
  background: linear-gradient(135deg, rgba(72, 11, 11, 0.60) 0%, rgba(120, 0, 0, 0.60) 100%);
}

.staking-notification-reload {
  padding-bottom: 6px;
}

.staking-notification .title {
  display: flex;
  align-items: center;
}

.staking-notification .title .icon {
  font-size: 26px;
  margin-left: 0px;
  margin-top: 0px;
}

.staking-notification .title .success {
  font-size: 24px;
  color: #53A86C;
}

.staking-notification .title .fail {
  color: #D0A304;
}

.staking-notification .title div {
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.staking-notification .description {
  color: #BFBFBF;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}

.staking-notification-reload .description {
  margin-bottom: 8px;
}

.staking-notification-reload .reload {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #68B7FF;
  width: max-content;
  margin-left: auto;
}

.staking-notification-reload .reload .icon {
  font-size: 26px;
  margin-top: -4px;
  margin-right: 4px;
}

@keyframes inAndOut {
  0% {
    margin-bottom: -116px;
    opacity: 0;
  }
  5% {
    margin-bottom: 16px;
    opacity: 1;
  }
  95% {
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    margin-bottom: -100px;
    opacity: 0;
  }
}

