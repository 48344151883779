.font {
  font-family: "AustenBold";
}
.bannersec h1 {
  text-align: left;
}

.bannersec ul {
  text-align: left;
}

.engpoints h3 {
  text-align: left;
}

.engpoints p {
  text-align: left;
}

.roadmapin {
  text-align: left;
}

.col-md-8 {
  text-align: left;
}
.psdata p {
  margin: 0 0 0 15px;
}

.roadmap_new label {
  opacity: 0;
}

.roadmap_new p:before {
  display: none;
}

@media screen and (max-width: 767px) {
  section.token .psdata p {
    margin: 0 0 0 5px !important;
  }
  section.token {
    padding: unset;
  }
}
@media screen and (min-width: 767px) {
  section.greenproject {
    left: 40%;
  }
  
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background: url("./assets/signupbg1.jpeg");
  color: white !important;
}

.MuiTable-root {
  color: white !important;
  border: 2px solid white;
}

.MuiTableCell-root {
  color: white !important;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #006b56;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: white;
  font-weight: 800;
}

.sign-up {
  cursor: pointer;
}

.Auth-form-container label {
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.evergreen_modal {
  width: 900px;
  height: auto;
  min-height: 200px;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.engpoints.paragraph{
  margin-bottom: unset !important;
  padding-bottom: unset !important;
}
.evergreen_modal.auto {
  width: 600px;
  padding: 0 !important;
}
.evergreen_modal .title {
  text-align: center;
  font-size: 30px;
}
.evergreen_modal .input-area {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-between;
  column-gap: 20px;
}
.evergreen_modal .input-area .input-box {
  flex: 1;
}

.evergreen_modal .input-area .input-box .abs {
  position: relative;
}
.evergreen_modal .input-area .input-box .abs input {
  border: 0;
  padding: 10px 15px;
  padding-right: 115px;
}
.evergreen_modal .input-area .input-box .abs .currency {
  position: absolute;
  z-index: 9;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 1px solid white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  min-width: 100px;
  background: #6dfecc;
  cursor: default;
  user-select: none;
  background: rgb(11, 26, 28);
  font-size: 13px;
}

.evergreen_modal .max-info {
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 10px;
  height: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.evergreen_modal .max-info .buy-btn {
  flex: 50%;
  border: 0;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}
.evergreen_modal .max-info .info {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  height: 100%;
  font-size: 12px;
  letter-spacing: 2px;
}
.evergreen_modal .buy-btn {
  border: 1px solid white;
  padding: 10px;
  transition: all 0.1s;
}

.evergreen_modal .buy-btn:hover {
  background: white !important;
  color: #0b1a1c;
}

@media screen and (max-width: 1200px) {
  .evergreen_modal {
    width: 90%;
    height: auto;
    padding: 20px;
  }
  .evergreen_modal.auto {
    height: auto;
    width: 90%;
  }
  .evergreen_modal .max-info {
    flex-direction: column;
    height: auto;
    padding: 10px;
    row-gap: 20px;
  }
  .evergreen_modal .max-info .buy-btn {
    border: 0;
  }
  .evergreen_modal .input-area {
    flex-direction: column;
    row-gap: 20px;
  }
}

.claim-container {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  color: white;
  margin-top: 200px;
}
.claim-container .data {
  width: clamp(50%, 100px, 100%);
  text-align: center;
  border: 1px solid white;
  border-radius: 10px;
  padding: clamp(5px, 10px, 30px);
}
.claim-container .data h2 {
  font-size: clamp(20px, 2.5vw, 30px);
  letter-spacing: 1px;
  width: 100%;
  margin: 0;
}
.responsive-img {
  width: 100%;
  max-width: 400px;
  height: auto;
}
.responsive-img.table {
  width: 200px;
}
.theworlds {
  display: block;
  animation: blinker 2.5s linear infinite;
}
.admin-container {
  margin-top: 150px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  color: white;
  align-items: center;
  row-gap: 30px;
}
.admin-container .title {
  width: 100%;
  text-align: left;
}
.admin-container p {
  text-align: center;
  margin: 0;
}
.admin-container .row-container {
  display: flex;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 20px;
}
.admin-container .row-container .control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-container .row-container .control .search {
  height: 100%;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  column-gap: 10px;
  padding: 10px;
  flex: 1;
}
.admin-container .row-container .control .search label {
  margin: 0;
  width: max-content;
  word-wrap: none;
}
.admin-container .row-container .control .search input {
  width: 100%;
  height: 42px;
  border: 0;
  outline: 0;
  border-radius: 42px;
  padding: 0 10px;
}
.load-more {
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  background: blue;
  width: max-content;
  height: 100%;
  cursor: pointer;
  background: #6dfecc;
  border-radius: 42px;
  min-width: 77px;
  text-align: center;
  line-height: 42px;
  color: #091619;
  font-size: 14px;
  display: inline-block;
  padding: 0 15px;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
}
.admin-container .row-container .user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid white;
  padding: 10px;
}
.admin-container .row-container .user-row .info {
  flex: 14%;
  border-radius: 10px;
  text-align: center;
}
.admin-container .row-container .user-row .info img {
  width: 100px;
}
.admin-container .row-container .user-row .info.button-action {
  background: #6dfecc;
  color: #091619;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 11px;
  height: auto;
  min-height: 60px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

p.abs {
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media screen and (max-width: 1200px) {
  .admin-container .row-container .control {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    padding: 0;
  }

  .admin-container .row-container .control .search {
    width: 100%;
  }
  .admin-container .row-container .control .load-more {
    width: 100%;
  }
  .admin-container .row-container {
    height: auto;
  }
  .admin-container .row-container .user-row.head {
    display: none;
  }
  .admin-container .row-container .user-row {
    flex-direction: column;
    row-gap: 10px;
    border: 1px solid white;
    border-radius: 10px;
  }
  .admin-container .row-container .user-row .info:hover {
    background: #6dfecb3a;
  }
  .admin-container .row-container .user-row .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    transition: all 0.2s;
  }
  .admin-container .row-container .user-row .info::before {
    content: attr(data-responsive);
  }
  .admin-container .row-container .user-row .info.button-action {
    width: 100%;
  }
}
.form-el-multiple {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.form-el-multiple div {
  width: 50%;
}
.form-el-multiple.textarea {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.update-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
}

.fix strong {
  color: #6dfecc;
}

.menu-item.dropdown {
  position: relative;
}
.menu-item.dropdown .dropdown-bar {
  background: none;
  border: 1px solid #6dfecc;
  border-radius: 0px;
  padding: 0px;
  width: 130px;
  min-width: inherit;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.menu-item.dropdown .dropdown-bar .dropdown-element {
  color: #6dfecc;
  text-align: center;
  padding: 6px 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #6dfecc;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #6dfecc;
  line-height: 18px;
  cursor: pointer;
}
.menu-item.dropdown .dropdown-bar .dropdown-element {
  color: #6dfecc;
  text-align: center;
  padding: 6px 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #6dfecc;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 18px;
  cursor: pointer;
}
.menu-item.dropdown .dropdown-bar .dropdown-element:hover {
  background: #6dfecc;
  color: #091619;
}

@media screen and (max-width: 991px) {
  .menu-item.dropdown .dropdown-bar {
    position: relative;
    left: 0;
    transform: translate(0);
    top: 20px;
    width: 100%;
  }
  .admin-container {
    padding: 10px;
  }
  .admin-container .row-container .user-row .info {
    text-align: left;
  }
}

.evergreen_modal.useracc {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #0b1a1c;
  z-index: 1000;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  height: auto;
  display: flex;
  height: auto;
  max-height: calc(100vh - 100px);
  overflow: auto;
  padding: 40px;
}
.evergreen_modal.useracc .admin-container.fix {
  min-height: unset;
  justify-content: flex-start;
  height: auto;
}
.evergreen_modal.useracc .top {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.evergreen_modal.useracc .top::after {
  content: "";
  width: 100%;
  height: 1px;
  background: white;
}

.evergreen_modal.useracc .bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.rewards {
  display: flex;
}
.rewards .reward-left {
  min-width: 50%;
}
.rewards .reward-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  margin-top: 4px;
}
.rewards .reward-right .top {
  font-size: 30px;
  line-height: normal;
  letter-spacing: 0.5px;
}
.rewards .reward-right .sub {
  flex: 1;
  font-size: 11px;
  letter-spacing: 1px;
  width: 80%;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-weight: normal;
}
@media screen and (max-width: 1200px) {
  .rewards {
    flex-direction: column;
  }
}
.menu-item.disabled a,
.buybtn.disabled a {
  opacity: 0.3;
  cursor: default;
  user-select: none;
}
.MuiTooltip-tooltip {
  background-color: #6dfecc !important;
  color: #091619 !important;
}
.greenbtn {
  padding: 0 15px;
}

.do-kyc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px auto 0 auto;
  padding: 16px 32px;
  background: #6dfecc;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 600;
  border: none;
  color: black;
}

.greenbtn[disabled] {
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.btn-modal .modal-body .content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.btn-modal .modal-body a:last-child {
  margin-left: 20px;
}

.btn-modal .modal-body a img {
  width: 250px;
}



.ido-modal .content {
  padding: 10px 0;
}

.ido-modal .content h1.p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ido-modal .content h1.p p{
  margin-bottom: 0;
  font-size: 20px;
}

.ido-modal .content h1 {
  display: block;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 20px;
}

.ido-modal .content h2 {
  font-size: 20px; 
  letter-spacing: 0px;
}

.ido-modal .content h3 {
  font-size: 20px;
  letter-spacing: 0px;
}

.ido-modal .modal-body, .btn-modal .modal-body {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    background-color: rgb(18, 18, 18);
    border: 2px solid rgb(0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    padding: 0;
}
.btn-modal .modal-body {
    width: 600px;
    background-color: white;
    border:none;
}
.ido-modal .modal-body .mobil {
    display: none;
  }

.ido-modal .modal-body .desktop {

}

.ido-modal .modal-body img {
  width: 100%;
}

.ido-modal .modal-body .close-btn , .btn-modal .modal-body .close-btn {
  position: absolute;
  right: -12px;
  border: none;
  top: -12px;
  width: 25px;
    height: 25px;
    color: black;
    outline: none;
}

.warning {
  position: absolute;
  background-color: #3c9360;
  z-index: 9;
  width: 100%;
  padding:0px 0px 6px 0px;
  top: 0;
}

.warning .content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 0px 0px;
}

.warning {
  text-align: center;
}

.warning .desc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning h1 {
  display: block;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0px;
}

.warning h1.p {
display: flex;
align-items: center;
font-weight: bold;
margin-left: 5px;
margin-right: 5px;
}

.warning h1.p p {
  font-size: 13px;
  margin-bottom: 0;
  line-height: 14px;
}


@media screen and (max-width: 991px) {
  .ido-modal .modal-body .desktop {
    display: none;
  }
  .ido-modal .modal-body .mobil {
    display: block;
  }
  .ido-modal .modal-body, .btn-modal .modal-body {
    width: 90%;
  }
  .btn-modal .modal-body a img {
    width:130px;
  }
  .btn-modal .modal-body .content {
    padding: 25px 0;
  }
  .ido-modal .content h1.p {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .claim-container .data {
    width: 90%;
  }
  .warning .container {
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .warning .content {
    flex-direction: column;
  }
  .warning .desc {
    flex-direction: column;
  }
  .warning h1.p {
    margin:3px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 475px) {
  
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
